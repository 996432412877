@import url('https://fonts.googleapis.com/css2?family=Tomorrow:wght@300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.polygon-button {
		clip-path: polygon(97% 0, 100% 55%, 100% 100%, 3% 100%, 0% 55%, 0 0);
	}

	.polygon-card {
		clip-path: polygon(100% 0%, 100% 80%, 85% 100%, 0% 100%, 0% 100%, 0 0);
	}

	.polygon-card-mobile {
		clip-path: polygon(90% 0, 100% 30px, 100% 100%, 0% 100%, 0% 100%, 0 0);
	}

	.indicator {
		clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
	}
}

@layer base {
	:root {
		--yellow-color: #fcee0a;
		--blue-color: #00f0ff;
		--unselected-color: #303030e0;
	}

	body {
		font-family: 'Tomorrow';
	}

	div {
		white-space: pre-wrap
	}

	h1 {
		font-family: 'Tomorrow';
		font-weight: 600;
		font-size: 60px
	}

	@media screen and (max-width: 1536px) {
		h1 {
			font-size: 45px
		}
	}

	@media screen and (max-width: 600px) {
		h1 {
			font-size: 35px
		}
	}
}

html {
	@apply text-gray-100
}